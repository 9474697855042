import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Col, Row, Container } from "react-bootstrap"

const TermsAndConditions = ({ data }) => {
  return (
    <Layout>
      <Seo
        title="Terms and Conditions - Signaclass"
        description="Our terms and conditions."
      />
      <Container>
        <Row>
          <Col sm="12" className="mt-5 pt-5 pb-3 text-center">
            <h1>
              <strong>Terms &amp; Conditions</strong>
            </h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="mb-5 pb-5">
            <div className="shadow border p-3">
              <p>
                We've drafted these terms (the &quot;Agreement&quot;) of service
                so you'll know the rules that govern our relationship with you
                (&quot;the customer&quot; / &quot;the user&quot;) of our
                application (&quot;Signaclass&quot;). Signaclass (&quot;The
                Software&quot; / &quot;The Service&quot;) is a
                software-as-a-service product released by Spacely OOD (herein
                referenced as &quot;we&quot;, &quot;us,&quot; &quot;our&quot;).
                By using Signaclass, you agree to be bound by this agreement, so
                please read it carefully.
              </p>

              <p>
                Spacely OOD is a company based at 36 Slavyanska, Plovdiv,
                Bulgaria, 4000, with company number 205256411. If you need to
                contact us, you can email us at info@signaclass.com.
              </p>

              <p>
                We may revise these terms and conditions at any time, and it is
                your (&quot;the user's&quot;) responsibility to ensure that you
                have read the updated version of our agreement. We will send you
                a notice by email when we have updated the terms.
              </p>

              <p>We last updated these terms of service on 1st June 2021.</p>

              <p>
                Our <Link to="/dpa">Data Processing Agreement</Link> is also
                attached to these Terms and Conditions. It outlines how we will
                process data received from individuals who use our service to
                attend your classes and how we will protect their data.
              </p>

              <p>
                <strong>1. Definitions</strong>
              </p>

              <p>1.1 &quot;Agreement&quot; means these terms of service.</p>

              <p>
                1.2 &quot;The Service&quot; is Signaclass, a
                Software-as-a-Service product that allows activity businesses to
                take payments online (to their stripe account). It also means
                the infrastructure used to run Signaclass, such as its servers,
                domains and databases. It also means its attached intellectual
                property, such as technical documentation, guides and branding.
              </p>

              <p>
                1.3 &quot;Class&quot; / &quot;Classes&quot; means an event,
                timeslot, activity, service appointment, or anything that an
                individual can purchase from a service user.
              </p>

              <p>
                1.4 &quot;The User&quot; / &quot;A User&quot; refers to
                &quot;you&quot;, the business or person who has created an
                account with Signaclass to manage their classes.
              </p>

              <p>
                1.5 &quot;Customer&quot; / &quot;A Customer&quot; / &quot;The
                Customer&quot; refers to an individual who registers on a user's
                Signaclass account whether they pay for classes or not.
              </p>

              <p>
                1.6 &quot;Personal Data&quot; has its meaning in the General
                Data Protection Regulation (GDPR) or other applicable law.
              </p>

              <p>
                1.7 &quot;Customer Data&quot; refers to all Personal Data
                supplied by a customer on Signaclass to a user.
              </p>

              <p>
                1.8 &quot;Participant&quot; / &quot;A Participant&quot; refers
                to an individual that a customer may provide data on as a
                potential physical attendee of classes, whether themselves or a
                child, or another person, they are responsible for, and whether
                they pay for classes or not.
              </p>

              <p>
                1.9 &quot;Participant Data&quot; refers to all Personal Data
                supplied by a customer on Signaclass about a Participant to a
                user.
              </p>

              <p>
                1.10 &quot;Fee&quot; is any amount owed to Spacely OOD by a user
                for using Signaclass.
              </p>

              <p>
                1.11 &quot;Content&quot; refers to any text, images, audio
                materials, video, posts, comments, or blogs that are uploaded,
                placed on, or otherwise transferred to Signaclass by a user,
                customer or participant or put there by us, including any
                trademarks, logos and brand materials.
              </p>

              <p>
                1.12 &quot;Fault&quot; refers to any failure, problem, error or
                bug in the service or providing the service.
              </p>

              <p>
                1.13 &quot;Businesses&quot; / &quot;Business&quot; means an
                entity undertaking activity to generate a profit and taxed in
                its country as a profit-making entity. It excludes schools,
                charities, not-for-profit organisations, individuals organising
                events or classes purely for fun, and governments and branches
                of governments, local or national.
              </p>

              <p>
                1.14 &quot;Stripe&quot; refers to Stripe Inc, 510 Townsend
                Street, San Francisco, CA 94103, USA or Stripe Payments Europe
                Limited, 1 Grand Canal Street Lower, Grand Canal Dock, Dublin,
                D02 H210, Ireland. Stripe provides payment processing services
                and APIs which allow Signaclass to function.
              </p>

              <p>
                1.15 &quot;Connected Account&quot; refers to an account with
                Stripe that can receive money owned by the user but connected to
                Signaclass so that we can facilitate payments through our
                software.
              </p>

              <p>
                <strong>2. Use of The Service</strong>
              </p>

              <p>
                2.1 You will use our service exclusively to list your class
                times, take customer and participant data to facilitate your
                classes, and take payments from customers and other related
                functions made available in the app.
              </p>

              <p>
                2.2 All information submitted by you (the user) and your
                customers belongs to you and is &quot;Your Data&quot;. Your data
                include personal data of your customers, their participants, and
                your business details, information about your classes, their
                schedules, locations, and information about your staff you may
                enter as &quot;Tutor Accounts.&quot;
              </p>

              <p>
                2.3 The service collects personal data about you (the user) when
                you register on our website (signaclass.com). By registering
                with the service, you consent to us processing your data.
              </p>

              <p>
                2.4. You agree that you have read our privacy policy carefully
                and consent to it and how we will process your data contained
                within.
              </p>

              <p>
                2.5 Furthermore, you consent that we will transfer your business
                details onto &quot;Stripe&quot; during the sign-up process to
                facilitate the creation of a &quot;Stripe Account&quot; attached
                to Signaclass for your business to allow you to process payments
                in line with the purpose of our service.
              </p>

              <p>
                2.6 You acknowledge that successfully creating a connected
                account at Stripe is a service condition. We are not responsible
                for any error, unlawful act or omission in your details,
                application or business that prevents or delays the creation of
                a connected account. You, the user, are ultimately responsible
                for the connected account.
              </p>

              <p>
                2.7 If we suspect you (the user) of using the service for fraud,
                illegal activity, or posting other objectionable or abusive
                content, including content that damages the reputation of
                Spacely Ltd, we reserve the right to suspend the service to you
                immediately and without prior notice.
              </p>

              <p>
                2.8 You acknowledge our service can be used by adults for
                themselves as participants or adults to arrange classes for
                individuals they are legally responsible for, such as their
                children. You, the user, agree to not use the service for
                classes marketed directly to under-18s and only to accept the
                data of under-18s with consent from parents or guardians.
              </p>

              <p>
                2.9 You agree that you will inform us if you suspect that you
                have gathered personal data of under 18s without a parent or
                guardian's consent so that we can avoid processing it, take
                action and delete this customer data or participant data
                promptly.
              </p>

              <p>
                2.10 You agree that the service will reject any customer who
                provides a date of birth that indicates that they are not over
                18 years (or the age of adulthood under your legislature,
                whichever is greater) during the sign-up process.
              </p>

              <p>
                <strong>3. Access to the Service</strong>
              </p>

              <p>
                3.1 You, the user, confirm that you have the necessary authority
                to create an account on the service for your business. 3.2 You,
                the user, confirm that you are a business as described in
                section 1 (&quot;Definitions&quot;). You will provide accurate
                information for verifying such, such as your company number or
                Tax / VAT Identification number.
              </p>

              <p>
                3.3 You, the user, agree to inform us if your status as a
                business changes before your next billing date so that we can
                ensure proper tax compliance.
              </p>

              <p>
                3.4 Access to the service will be made available to you at a
                Signaclass sub-domain you have chosen during the service set-up.
                You can log in by clicking the appropriate button on your
                dashboard.
              </p>

              <p>
                3.5 You may not sell or trade access to the service, or your
                account on the service, to any other person or business. 3.6 You
                agree not to share your login details (email and password) with
                any third parties and maintain reasonable security practices
                concerning the service, such as logging out when you have
                finished working and changing your password if a device is
                stolen.
              </p>

              <p>
                3.7 You agree that we will not be liable for damages or loss
                caused by you disclosing your login details to third parties or
                not maintaining good security practices.
              </p>

              <p>
                3.8 You agree that you are liable for loss or damages if you
                expose personal data through your disclosure of login details or
                failure to maintain reasonable security practices. You agree to
                indemnify us and protect us from such claims. In such cases,
                your login details will serve as evidence of improper access to
                our service, and we may use other information such as IP
                addresses.
              </p>

              <p>
                3.9 Providing us with any false, misleading or fraudulent
                information may result in the termination of your account. That
                includes providing fraudulent or otherwise incorrect details of
                you (the user) or your business.
              </p>

              <p>
                3.10 If we suspend or terminate your account, you will not be
                entitled to operate another report on the service.
              </p>

              <p>
                3.11 We reserve the right to terminate and suspend accounts
                indefinitely in response to a user's unlawful or objectionable
                actions or when payments for the service are not made.
              </p>

              <p>
                3.12 You, the user, may cancel the service, for any reason,
                through your dashboard or contact us at least three working days
                before the next billing period.
              </p>

              <p>
                3.13 We will take action to remove illegal, unlawful,
                fraudulent, misleading or morally reprehensible content from the
                service, including deletion and alteration of content and
                suspension or termination of your account on the service.
              </p>

              <p>
                <strong>4. Fees and Billing</strong>
              </p>

              <p>
                4.1 Fees for the service are structured as a monthly (calendar
                month) subscription and offered in &quot;Plans&quot;, available
                on our website (signaclass.com).
              </p>

              <p>
                4.2 We may offer a time-limited free trial of the service during
                your set-up at our discretion.
              </p>

              <p>
                4.3 When you set up your account with Signaclass, you will
                select your plan and be requested to enter your card details. We
                will charge your card after the free trial period has expired if
                we offer you a free trial. If we do not offer you a free trial,
                we will charge your card immediately.
              </p>

              <p>
                4.4 Your billing date will be the date you set up your
                subscription for the service, plus any free trial you received.
                4.5 You may cancel the service before the next billing date by
                clicking the cancel button in your dashboard.
              </p>

              <p>
                4.6 It is your, the user's, responsibility to determine the
                prices of your products and services offered to your customers
                using our service.
              </p>

              <p>
                4.7 We reserve the right to suspend or terminate your access to
                the service in the event fees for the service are not paid on
                your billing date by you.
              </p>

              <p>
                4.8 You, the user, will ensure you provide us with accurate
                details to ensure the proper charging of VAT and tax. Such
                details include your VAT or Tax ID.
              </p>

              <p>
                4.9 VAT will be charged if your business is registered in
                Bulgaria or the EU and does not supply a valid VAT
                identification number.
              </p>

              <p>
                4.10 Natural Persons in the EU not operating as a business or
                without a valid VAT number will be charged at their country's
                local rate.
              </p>

              <p>
                4.11 We will not charge VAT to businesses with a valid VAT
                number within the EU, businesses outside the EU, or natural
                persons outside the EU.
              </p>

              <p>
                <strong>5. Your Rights and Obligations</strong>
              </p>

              <p>
                5.1 By registering an account with the service, you, the user,
                agree to these terms and conditions.
              </p>

              <p>
                5.2 You, the user, are granted non-transferable, non-exclusive
                rights to use the service.
              </p>

              <p>
                5.3 We reserve all other rights not expressly given in this
                agreement.
              </p>

              <p>
                5.4 It is your, the user's, responsibility to inform all
                customers that we are storing and processing their data as a
                subprocessor under this agreement and the attached Data
                Processing Agreement (&quot;DPA&quot;).
              </p>

              <p>
                5.5 It is your responsibility to respond to any customer
                requests for support using our service to interact with your
                business. Examples of requests may include but are not limited
                to requests for information on registering for your classes or
                cancelling a subscription to your classes.
              </p>

              <p>
                5.6 You must not use the service or allow anyone to use the
                service in any unlawful way or in a way that promotes illegal
                actions, hate, violence or other immoral or outrageous
                behaviour. We reserve the right to terminate your account in
                such cases immediately.
              </p>

              <p>
                5.7 You must not attempt to reverse engineer the service or our
                website.
              </p>

              <p>
                5.8 You must not attempt to copy the service or gain
                unauthorised access to the service's source code, servers,
                databases, networks or any hardware the service uses.
              </p>

              <p>
                5.9 You must not sublicense or resell the service to anyone.
              </p>

              <p>
                5.10 You agree that any sub-accounts you set up on the service,
                including tutor accounts, are also bound by this agreement.
              </p>

              <p>
                5.11 You must not abuse, copy, reproduce or transfer any of our
                trademarks or branding, or intellectual property, such as text
                from our website or images, without express permission.
              </p>

              <p>
                5.12 You must not use the service to distribute spam or
                undertake other annoying, unlawful or immoral advertising
                practices.
              </p>

              <p>5.13 You must not harm our reputation by using the service.</p>

              <p>
                5.14 You must report faults or suspected faults by email to
                info@signaclass.com or via the chat widget and include
                information about the fault and your contact details so we can
                respond.
              </p>

              <p>
                5.15 You must make us aware promptly of any breaches or
                violations of this agreement by email to info@signaclass.com
              </p>

              <p>
                5.16 You are not to use the service to advertise or sell classes
                directly to people under 18 years of age (or the age of
                adulthood in your legislature if it is greater). You must seek
                the consent of parents or guardians before subscribing children
                to your classes.
              </p>

              <p>
                <strong>6. Our Rights and Obligations</strong>
              </p>

              <p>
                6.1 We reserve the right to change the service and its features,
                adjust its content, look, design or function, or withdraw our
                service without notice.
              </p>

              <p>
                6.2 From time to time, the service will require
                &quot;maintenance&quot; to prevent future problems or action
                improvements. We reserve the right to withdraw the service or
                restrict access during maintenance periods to both you, the
                user, your customers and all other visitors to the service.
              </p>

              <p>
                6.3 There is no obligation for us to improve, add features,
                functions, maintain or otherwise alter the service for your, the
                user's benefit or at your request.
              </p>

              <p>
                6.4 We are not required to have backup facilities, take backups
                of your data or convert data to another format than that which
                we use in the service.
              </p>

              <p>
                6.5 Whilst at no point will other Users have access to your data
                or your instance of the service, we may offer the service to
                anyone we see fit, including your competitors, without
                restriction or notice and without providing exclusivity, in any
                way, to you.
              </p>

              <p>6.6 We reserve the right to refuse access to the service.</p>

              <p>
                6.7 Under no circumstances will we provide a physical device
                (for example, a &quot;USB Stick&quot; or &quot;CD-ROM&quot;)
                containing the service, its source code or anything required to
                run the service.
              </p>

              <p>
                6.8 Signaclass collects data from the customers, the users and
                third party services such as Stripe. We are not responsible for
                the accuracy or completeness of information on the service, and
                it is your responsibility to ensure data is accurate before
                relying on it.
              </p>

              <p>
                6.9 We follow strict security policies, and all personal
                information submitted is stored securely, safely and
                identifiably separate from other users.
              </p>

              <p>
                6.10 We shall not disclose your data, or those of your customers
                or their participants, to any third party except:
              </p>

              <p>6.10.1 When we are required to by law.</p>

              <p>
                6.10.2 When outlined in our Data Processing Agreement, attached
                to these terms.
              </p>

              <p>
                6.10.3 We have grounds to believe that fraud may occur or has
                occurred on the service to protect ourselves from the risk of
                being involved in illegal transactions.
              </p>

              <p>
                6.11 In the event of a dispute between two or more individuals
                about ownership of an account on the service, we reserve the
                right to take action to resolve the matter, including the
                gathering of personal identification documents and payment
                information. We also reserve the right to suspend the service
                until the resolution of the dispute.
              </p>

              <p>
                6.12 We reserve the right to terminate the service to a user in
                the event of threats or abuse, whether verbal or written.
              </p>

              <p>
                <strong>7. Content on the Service</strong>
              </p>

              <p>
                7.1 You are ultimately responsible for all the content you write
                into or upload to the service, and we do not claim any
                intellectual property rights over your content.
              </p>

              <p>
                7.2 We do not monitor your content, but you, the user, agree not
                to upload illegal or harmful content, fraudulent content,
                sexually explicit content, content that is abusive or which
                infringes on the copyright or intellectual property of others.
              </p>

              <p>
                7.3 You agree to indemnify us for any breach of relevant laws
                and regulations or claims resulting from content you put on the
                service.
              </p>

              <p>
                7.4 By placing your content on the service, you agree to us
                publishing it and making it publicly accessible on the internet,
                viewable by your customers and their participants, indexable by
                search engines and otherwise freely available.
              </p>

              <p>
                7.5 You grant us a worldwide, royalty-free, and non-exclusive
                right and license to use, modify, distribute and transmit your
                content, including your trademarks and logos, over the service,
                including, without limitation, third-party websites, APIs,
                emails and other formats.
              </p>

              <p>
                7.6 We reserve the right to delete or alter your content if it
                does not comply with 7.2.
              </p>

              <p>
                7.7 You agree that some content may continue to exist in data
                backups or exist otherwise in line with this agreement when you
                delete content.
              </p>

              <p>
                7.8 You, the user, agree to receive occasional information about
                the service, our products and other updates by email. You may
                opt out by clicking the &quot;unsubscribe&quot; button.
              </p>

              <p>
                <strong>
                  8. Limitations of Liability, Indemnification and Disclaimers
                </strong>
              </p>

              <p>
                8.1 the contents of both our website and the service are without
                any guarantee, conditions or warranties as to their accuracy,
                reliability or content.
              </p>

              <p>
                8.2 In no event shall we be liable to the user or any third
                party for any indirect, incidental, special, consequential,
                punitive or exemplary damages (including, but not limited to,
                damages based upon lost profits, business interruption, lost
                business, lost data or lost savings) for any fault or inaccuracy
                in the service, or the user's inability to use the service, even
                if you or others have told us about them.
              </p>

              <p>
                8.3 The Service is provided as-is. We give no warranty that The
                Service will be uninterrupted, secure, timely, or virus free.
                Use of The Service, and this website, are at your sole risk.
              </p>

              <p>
                8.4 You agree that we are not liable for any content you place,
                upload, create, transmit or transfer on the service.
              </p>

              <p>
                8.5 You agree that it is at your discretion and risk to use the
                service, and we will not be liable for any damage to your
                computer, device, phone or any other thing you use to access the
                service.
              </p>

              <p>
                8.6 You agree that we will not be liable for your actions using
                the service, including any resulting loss or damage to you or
                any other party.
              </p>

              <p>
                8.9 You, the user, agree to indemnify us, as well as our
                directors, employees and contractors, for any expenses, losses,
                costs, charges, proceedings, claims or other damages that may be
                held against us as a result of you using the service or our
                website, including copyright claims.
              </p>

              <p>
                <strong>9. Force Majeure</strong>
              </p>

              <p>
                9.1 Neither party to this agreement shall be liable for a
                failure to perform any obligations in this agreement when such a
                failure results from a &quot;Force Majeure&quot; or &quot;act of
                god&quot;. These events include, but are not limited to, fire,
                flood, earthquake, or other natural disasters, war, invasion,
                rebellion, revolution, civil war, hostility and acts of enemies,
                terrorism, nationalisation, confiscation, government sanction,
                embargo, blockage, strike, labour dispute, failure of
                electricity or telephone service, attack of computer viruses or
                cyber-attacks, breakdown of electronic and communication systems
                and any such events occurring to our subcontractors or
                third-parties.
              </p>

              <p>
                <strong>10. Severability</strong>
              </p>

              <p>
                10.1 If any provision of this agreement is determined to be
                void, invalid, unenforceable or illegal for whatever reason,
                such provision(s) shall be null and void; provided, however,
                that the remaining provisions of this agreement shall be
                unaffected, thereby and shall continue to be valid and
                enforceable.
              </p>

              <p>
                10.2 Any finding that a provision of this agreement is
                unenforceable in any jurisdiction does not affect the validity
                and enforceability in other jurisdictions or other provisions of
                this agreement in that jurisdiction.
              </p>

              <p>
                <strong>11. Jurisdiction</strong>
              </p>

              <p>
                11.1 This Agreement is governed by the laws and courts of The
                Republic of Bulgaria.
              </p>

              <p>
                11.2 Any dispute that may arise about this agreement shall be
                settled within the courts and laws of The Republic of Bulgaria.
              </p>

              <p>
                <strong>12. Entire Agreement</strong>
              </p>

              <p>
                12.1 This, and the attached is the entire agreement between you
                and us. This agreement supersedes any prior oral or written
                agreements, however delivered.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default TermsAndConditions
